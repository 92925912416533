<template>
  <div class="signatureManagement">
    <div class="signatureManagement-search">
      <div class="signatureManagement-search-1">
        签名:
        <a-input v-model="fuzzyName" placeholder="请输入签名" allowClear />
      </div>
      <div class="signatureManagement-search-1">
        标签:
        <a-select
          v-model="category"
          placeholder="请选择标签"
          style="width: 240px"
        >
          <a-select-option
            v-for="i in categoryList"
            :key="i.value"
            :value="i.value"
          >
            {{ i.key }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="signatureManagement-search-button">
      <a-button type="primary" @click="inquire(1)">查询</a-button>
      <i style="display: inline-block; margin-right: 20px"></i>
      <a-button type="primary" @click="addSign">新增</a-button>
    </div>
    <!-- 签名列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(signColumns)"
      :columns="signColumns"
      :dataSource="signTableData"
      :pagination="signPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="state" slot-scope="text" style="width: 100%">
        <a-tag v-if="text === 'AUDIT_SUCCESS'" color="green"> 审核通过 </a-tag>
        <a-tag v-if="text === 'AUDIT_FAILURE'" color="red"> 审核失败 </a-tag>
        <a-tag v-if="text === 'WAIT_AUDIT'" color="orange"> 待审核 </a-tag>
      </span>
      <span slot="enabled" slot-scope="text, record" style="width: 100%">
        <div class="switch-button">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="text"
            @change="signOnChange($event, record)"
          />
        </div>
      </span>
      <span slot="blackEnabled" slot-scope="text, record" style="width: 100%">
        <div class="switch-button">
          <a-switch
            checkedChildren="开启"
            unCheckedChildren="关闭"
            v-model="text"
            @change="blackOnChange($event, record)"
          />
        </div>
      </span>
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a @click="editCategory(record)">修改标签</a>
        <a-divider type="vertical" />
        <a @click="signRecentSuccess(record)">最近发送成功过滤</a>
        <a-divider type="vertical" />
        <a
          :disabled="!record.blackEnabled"
          @click="linkOtherSignBlackList(record)"
          >关联签名黑名单</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增签名"
      v-model="addSignShow"
      :maskClosable="false"
      :afterClose="addSignClose"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="签名" required style="position: relative">
          <a-input v-model="signName" placeholder="请输入签名" />
        </a-form-item>
        <a-form-item label="标签" required style="position: relative">
          <a-select
            v-model="categoryAdd"
            placeholder="请选择标签"
            style="width: 240px"
          >
            <a-select-option
              v-for="i in categoryList"
              :key="i.value"
              :value="i.value"
            >
              {{ i.key }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="addSignShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="addSign_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="关联签名黑名单"
      v-model="linkOtherSignBlackShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="签名">
          <a-select
            v-model="signList"
            placeholder="请选择签名"
            class="select-tag"
            mode="multiple"
            @search="getSignSelectList"
          >
            <a-select-option
              v-for="i in signSelectList"
              :key="i.id"
              :value="i.sign"
              >{{ i.sign }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="linkOtherSignBlackShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="linkOtherSignBlackList_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改标签"
      v-model="editCategoryShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="标签" required>
          <a-select
            v-model="categoryAdd"
            placeholder="请选择标签"
            style="width: 240px"
          >
            <a-select-option
              v-for="i in categoryList"
              :key="i.value"
              :value="i.value"
            >
              {{ i.key }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="editCategoryShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="editCategory_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="最近发送成功过滤"
      v-model="signRecentSuccessShow"
      :maskClosable="false"
      class="actionSub-class"
    >
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="启用状态:">
          <a-switch
            checkedChildren="启用"
            unCheckedChildren="禁用"
            v-model="recentSuccessFilterEnabled"
          />
        </a-form-item>
        <a-form-item label="过滤签名集合:" v-if="recentSuccessFilterEnabled">
          <a-select
            v-model="recentSuccessFilterCollectionId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="i in signRecentSuccessList"
              :key="i.id"
              :value="i.id"
            >
              {{ i.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="recentSuccessFilterEnabled">
          <span slot="label">
            过滤时间
            <a-tooltip placement="topLeft">
              <template slot="title">过滤掉设置的时间以内的号码</template>
              <a-icon type="info-circle" />
            </a-tooltip>
          </span>

          <a-input
            v-model="expireTime"
            placeholder="请输入过期时间"
            style="width: 200px"
          >
            <a-select
              slot="addonAfter"
              v-model="expireUnit"
              style="width: 65px"
            >
              <a-select-option value="天"> 天 </a-select-option>
              <a-select-option value="小时"> 小时 </a-select-option>
            </a-select>
          </a-input>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="signRecentSuccessShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="signRecentSuccess_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "signatureManagement",
  data() {
    return {
      fuzzyName: "",
      signIdAdd: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 80,
        },
        {
          title: "sign",
          ellipsis: true,
          dataIndex: "sign",
          width: 300,
        },
        {
          title: "标签",
          ellipsis: true,
          dataIndex: "category",
          width: 100,
        },
        {
          title: "启用",
          ellipsis: true,
          dataIndex: "enabled",
          scopedSlots: { customRender: "enabled" },
          width: 100,
        },
        {
          title: () => {
            return (
              <span>
                黑名单开关&nbsp;
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    开启后， 发送时会过滤签名黑名单库（包含关联签名黑名单库）
                  </template>
                  <a-icon type="info-circle" />
                </a-tooltip>
              </span>
            );
          },
          ellipsis: true,
          dataIndex: "blackEnabled",
          scopedSlots: { customRender: "blackEnabled" },
          width: 120,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 180,
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      addSignShow: false,
      signName: "",
      platform: "",
      platformList: [],
      currentPage: 1,
      pageSize: 10,
      accessKey: "",
      signList: [],
      linkOtherSignBlackShow: false,
      signSelectList: [],
      category: undefined,
      categoryAdd: undefined,
      categoryList: [],
      editCategoryShow: false,
      id: "",
      signRecentSuccessList: [],
      signRecentSuccessShow: false,
      recentSuccessFilterEnabled: false,
      recentSuccessFilterCollectionId: undefined,
      expireTime: "",
      expireUnit: "小时",
    };
  },
  mounted() {
    this.getCategoryList();
    this.inquire(1);
    this.getSignSelectList();
    this.getSignRecentSuccessList();
  },
  methods: {
    getCategoryList() {
      this.$http.get("/shortUrl/getCategoryList").then((res) => {
        if (res.result === 200) {
          this.categoryList = res.data;
        }
      });
    },
    linkOtherSignBlackList_submit() {
      let data = {
        id: this.signIdAdd,
        signList: this.signList,
      };
      this.$http
        .json_post("/sms/signInfo/update/linkOtherSignBlackList", data)
        .then((res) => {
          if (res.result == 200) {
            this.$message.success("关联成功");
            this.linkOtherSignBlackShow = false;
          }
        });
    },
    getSignSelectList(fuzzyName) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        fuzzyName,
      };
      this.$http.get("/sms/signInfo/list", data).then((res) => {
        if (res.result == 200) {
          this.signSelectList = res.data.records;
        }
      });
    },
    linkOtherSignBlackList(record) {
      this.$http
        .get("/sms/signInfo/update/getLinkOtherSign", { id: record.id })
        .then((res) => {
          if (res.result == 200) {
            this.signIdAdd = record.id;
            this.signList = res.data;
            this.linkOtherSignBlackShow = true;
          }
        });
    },
    blackOnChange(blackEnabled, val) {
      let title = "开启";
      if (blackEnabled) {
        title = "开启";
      } else {
        title = "关闭";
      }
      this.$confirm({
        title: "提示",
        content: "是否" + title + "【" + val.sign + "】签名？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/sms/signInfo/update/blacklistEnabled", {
              id: val.id,
              blackEnabled,
            })
            .then((res) => {
              if (res.result === 200) {
                if (blackEnabled) {
                  this.$message.success("开启成功");
                } else {
                  this.$message.success("关闭成功");
                }
                this.inquire();
              }
            });
        },
      });
    },
    // 签名启用or禁用
    signOnChange(e, val) {
      let title = "启用";
      if (e) {
        title = "启用";
      } else {
        title = "禁用";
      }
      this.$confirm({
        title: "提示",
        content: "是否" + title + "【" + val.sign + "】签名？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.$http
            .json_post("/sms/signInfo/update/status", {
              id: val.id,
              enabled: e,
            })
            .then((res) => {
              if (res.result === 200) {
                if (e) {
                  this.$message.success("启用成功");
                } else {
                  this.$message.success("禁用成功");
                }
                this.inquire();
              }
            });
        },
      });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        fuzzyName: this.fuzzyName,
        category: this.category,
      };
      this.getSignList(data);
    },
    // 点击新增
    addSign() {
      this.categoryAdd = "默认分类";
      this.addSignShow = true;
      this.getPlatformList();
    },
    getSignRecentSuccessList(name) {
      this.$http.get("/signRecentSuccess/list", { name }).then((res) => {
        if (res.result == 200) {
          this.signRecentSuccessList = res.data;
        }
      });
    },
    signRecentSuccess(val) {
      this.signIdAdd = val.id;
      this.recentSuccessFilterEnabled = val.recentSuccessFilterEnabled;
      if (val.recentSuccessFilterCollectionId !== -1) {
        this.recentSuccessFilterCollectionId =
          val.recentSuccessFilterCollectionId;
      } else {
        this.recentSuccessFilterCollectionId = undefined;
      }
      if (val.recentSuccessFilterMinute) {
        let recentSuccessFilterMinute = val.recentSuccessFilterMinute;
        if (recentSuccessFilterMinute % 1440 == 0) {
          this.expireTime = recentSuccessFilterMinute / 1440;
          this.expireUnit = "天";
        } else {
          this.expireTime = recentSuccessFilterMinute / 60;
          this.expireUnit = "小时";
        }
      } else {
        this.expireTime = undefined;
        this.expireUnit = "小时";
      }
      this.signRecentSuccessShow = true;
    },
    signRecentSuccess_submit() {
      let data = {
        signId: this.signIdAdd,
        enabled: this.recentSuccessFilterEnabled,
      };
      if (this.recentSuccessFilterEnabled) {
        data.collectionId = this.recentSuccessFilterCollectionId;
        if (this.expireUnit == "小时") {
          data.minutes = this.expireTime * 60;
        } else {
          data.minutes = this.expireTime * 1440;
        }
      }
      this.$http
        .json_post("/signInfo/enableRecentSuccessFilter", data)
        .then((res) => {
          if (res.result === 200) {
            this.signRecentSuccessShow = false;
            this.inquire();
            this.$message.success("操作成功");
          }
        });
    },
    editCategory(record) {
      this.id = record.id;
      this.categoryAdd = record.category;
      this.editCategoryShow = true;
    },
    editCategory_submit() {
      let data = {
        id: this.id,
        category: this.categoryAdd,
      };
      this.$http.json_post("/sms/signInfo/edit", data).then((res) => {
        if (res.result === 200) {
          this.editCategoryShow = false;
          this.inquire();
          this.$message.success("修改成功");
        }
      });
    },
    // 确认新增
    addSign_submit() {
      let data = {
        sign: this.signName,
        signId: this.signName,
        accessKey: this.accessKey,
        category: this.categoryAdd,
      };
      if (!data.sign) {
        this.$message.warning("请输入签名");
        return;
      }
      if (data.sign.indexOf("【") !== -1 || data.sign.indexOf("】") !== -1) {
        this.$message.warning("签名不能包含中文括号【】");
        return;
      }
      this.$http.json_post("/sms/signInfo/add", data).then((res) => {
        if (res.result === 200) {
          this.addSignShow = false;
          this.inquire(1);
          this.$message.success("新增成功");
        }
      });
    },
    // 关闭新增
    addSignClose() {
      this.platformList = [];
      this.signName = "";
      this.platform = "";
      this.accessKey = "";
      this.categoryAdd = undefined;
    },
    // 选择新增平台
    platformChange(val) {
      // console.log(val);
      this.platformList.forEach((item) => {
        if (val === item.id) {
          this.accessKey = item.value;
        }
      });
    },
    // 查询签名列表
    getSignList(data) {
      this.loadingTable = true;
      this.$http
        .get("/sms/signInfo/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.signChangePage(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 签名分页
    signChangePage(index) {
      this.inquire(index);
    },
    // 查询平台
    getPlatformList() {
      this.platformList = [];
      this.$http.get("/sms/user/platformList").then((res) => {
        if (res.result === 200) {
          let list = res.data;
          list.forEach((item) => {
            this.platformList.push({
              label: item.name,
              value: item.accessKey,
              code: item.code,
              id: item.id,
            });
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.signatureManagement {
  .signatureManagement-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .signatureManagement-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .signatureManagement-search-button {
    margin-bottom: 20px;
  }
}
</style>
