<template>
  <div class="smsmarketing">
    <div class="left">
      <div class="dropbox">
        <a-button class="box-btn" @click="batchImport">批量导入</a-button>
        <a-button class="box-btn" @click="selectBatch">选择批次</a-button>
        <a-button
          class="box-btn btn-color"
          @click="saveBatch"
          v-if="saveBatchShow"
          >保存批次</a-button
        >
        <div class="number-pool">
          <div class="spin-pool" v-if="isUploading">
            <a-spin />
          </div>
          <div class="number-pool-item">
            <div>有效号码</div>
            <a
              href="javascript:;"
              class="number"
              :disabled="isdetailsClick"
              @click="detailsClick()"
              >{{ pooiForm.validNumber }}</a
            >
          </div>
          <div class="number-pool-item">
            <div>移动</div>
            <a
              href="javascript:;"
              class="number"
              :disabled="isdetailsClick"
              @click="detailsClick('中国移动')"
              >{{ pooiForm.moveNumber }}</a
            >
          </div>
          <div class="number-pool-item">
            <div>联通</div>
            <a
              href="javascript:;"
              class="number"
              :disabled="isdetailsClick"
              @click="detailsClick('中国联通')"
              >{{ pooiForm.unicomNumber }}</a
            >
          </div>
          <div class="number-pool-item">
            <div>电信</div>
            <a
              href="javascript:;"
              class="number"
              :disabled="isdetailsClick"
              @click="detailsClick('中国电信')"
              >{{ pooiForm.telecomNumber }}</a
            >
          </div>
        </div>
        <div class="title-number-pool" v-if="isSelectBatchNameLabel">
          当前批次：{{ selectBatchNameLabel }}
        </div>
        <div style="margin: 0px 0px 20px 0px; font-weight: bold">
          <span>精准发送：</span>
          <a-switch v-model="isPrecise" @change="preciseChange" size="small" />
          <a-checkbox-group
            v-model="operatorList"
            class="operatorList-checkbox"
            v-if="isPreciseCheck"
          >
            <a-checkbox value="中国移动">移动</a-checkbox>
            <a-checkbox value="中国联通">联通</a-checkbox>
            <a-checkbox value="中国电信">电信</a-checkbox>
          </a-checkbox-group>
        </div>
      </div>
      <div class="sign-input">
        <span class="title">发送名称：</span>
        <a-input v-model="msgName" placeholder="请输入名称" allowClear />
      </div>
      <div class="sign">
        <span class="title">选择账号：</span>
        <a-select
          v-model="userSmsAccountId"
          placeholder="请选择账号"
          @change="userSmsChange"
          class="select-tag"
          showSearch
          @search="userSearch"
          :filterOption="filterOption"
        >
          <a-select-option
            v-for="i in userLoginNameList"
            :key="i.id"
            :value="i.id"
            >{{ i.accessKey }} | {{ i.alias }}</a-select-option
          >
        </a-select>
      </div>
      <div class="sign">
        <span class="title">选择签名：</span>
        <a-select
          :value="sign"
          @change="sighChange"
          placeholder="请选择签名"
          class="select-tag"
          showSearch
          :filterOption="filterOption1"
        >
          <a-select-option
            v-for="i in signList"
            :key="i.sign"
            :value="i.sign"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="smsContent">
        <span class="title">短信内容：</span>
        <a-tabs v-model="active" @change="callback">
          <a-tab-pane key="1" tab="短信内容">
            <div class="template">
              <span @click="smsContentTemplateClick" style="cursor: pointer">
                使用模版
              </span>
            </div>
            <sms-input
              :shortUrlShow="false"
              :textareaSMS="textarea"
              :sign="sign"
              @change="textarea_sms"
            ></sms-input>
          </a-tab-pane>
          <a-tab-pane key="2" tab="选择模板">
            <a-select
              v-model="strategyId"
              placeholder="请选择模板"
              class="select-tag"
              showSearch
              @search="getStrategyList"
            >
              <a-select-option
                v-for="i in strategyList"
                :key="i.id"
                :value="i.id"
                >{{ i.name }}</a-select-option
              >
            </a-select>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="sign-input" v-if="mode == 'development' || mode == 'test'">
        <span class="title">创建批次数(测试环境专用)：</span>
        <a-input v-model="numberOfBatchesCreated" style="width: 100px" />
      </div>
      <div style="margin-bottom: 20px; font-weight: bold">
        <a-checkbox v-model="checkedTiming">定时发送</a-checkbox>
        <a-date-picker
          v-if="checkedTiming"
          showTime
          format="YYYY-MM-DD HH:mm:ss"
          v-model="time"
          placeholder="选择日期时间"
        />
      </div>
      <div style="margin-bottom: 20px; font-weight: bold">
        <a-checkbox v-model="blankNumberCheck">空号检测</a-checkbox>
        <a-tooltip placement="topLeft" style="margin-left: 5px">
          <template slot="title">
            随机选择若干空号插入到批次，进行空号检测<br />
            开启空号检测后,发送条数会增多(最多100条)
          </template>
          <a-icon type="info-circle" />
        </a-tooltip>
      </div>
      <div style="margin: 0px 0px 20px 0px">
        <a-button
          type="primary"
          style="margin-right: 20px"
          @click="sendTiming_submit()"
          :disabled="isUpload"
          >提交审核</a-button
        >
      </div>
    </div>
    <div class="right">
      <div
        class="bubble"
        v-if="this.sign !== undefined || this.textarea !== ''"
      >
        {{ sign }}{{ textarea }}
      </div>
    </div>

    <a-modal
      title="批量导入"
      v-model="batchImportShow"
      :maskClosable="false"
      width="400px"
      :afterClose="batchImportClose"
      class="actionSub-class"
    >
      <a-upload-dragger
        name="file"
        :multiple="true"
        :fileList="fileList"
        :remove="handleRemoveMale"
        :beforeUpload="beforeUploadMale"
        @change="handleChangeFileList"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
      </a-upload-dragger>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="batchImportShow = false">取消</a-button>
          <a-button
            key="submit"
            type="primary"
            @click="batchImport_submit"
            :disabled="isUploadBatch"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 保存批次 -->
    <a-modal
      title="保存批次"
      v-model="saveBatchModalShow"
      :maskClosable="false"
      width="400px"
      :afterClose="saveBatchClose"
      class="actionSub-class"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 16 }"
          label="批次名称"
        >
          <a-input
            v-model="batchName"
            placeholder="请输入批次名称"
            allowClear
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="saveBatchModalShow = false"
            >取消</a-button
          >
          <a-button
            key="submit"
            type="primary"
            @click="saveBatch_submit"
            :disabled="isSaveBatch"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
    <!-- 选择批次 -->
    <a-modal
      title="选择批次"
      v-model="selectBatchShow"
      :maskClosable="false"
      width="900px"
      :afterClose="selectBatchClose"
      class="actionSub-class"
      :footer="null"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(selectBatchColumns)"
        :columns="selectBatchColumns"
        :dataSource="selectBatchTable"
        :pagination="selectBatchPagination"
        size="small"
      >
        <span slot="status" slot-scope="text, record" style="width: 100%">
          <a-tag v-if="record.status === 'CREATED'" color="green">{{
            text
          }}</a-tag>
          <a-tag v-if="record.status === 'CREATEING'" color="orange">{{
            text
          }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record" style="width: 100%">
          <a href="javascript:;" @click="batchUse(record)">使用批次</a>
        </span>
      </a-table>
    </a-modal>
    <!-- 批次详情 -->
    <a-modal
      title="批次详情"
      v-model="batchDetailListShow"
      :maskClosable="false"
      width="900px"
      :afterClose="batchDetailListClose"
      class="actionSub-class"
      :footer="null"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(batchDetailListColumns)"
        :columns="batchDetailListColumns"
        :dataSource="batchDetailListTable"
        :pagination="batchDetailListPagination"
        size="small"
      >
      </a-table>
    </a-modal>
    <!-- 短信模版 -->
    <smsContentTemplate
      :show="smsContentTemplateShow"
      :width="900"
      @close="smsContentTemplateClose"
    ></smsContentTemplate>
  </div>
</template>

<script>
import reqwest from "reqwest";
import smsInput from "../tool/smsInput.vue";
import smsContentTemplate from "../tool/smsContentTemplate.vue";
export default {
  name: "smsmarketing",
  components: {
    smsInput,
    smsContentTemplate,
  },
  data() {
    return {
      smsContentTemplateShow: false,
      operator: "",
      batchDetailListShow: false,
      batchDetailListColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 150,
        },
        {
          title: "省份",
          ellipsis: true,
          dataIndex: "province",
          width: 100,
        },
        {
          title: "城市",
          ellipsis: true,
          dataIndex: "city",
          width: 100,
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "operator",
          width: 100,
        },
      ],
      batchDetailListTable: [],
      batchDetailListPagination: {},
      isPreciseCheck: false,
      isPrecise: false,
      operatorList: [],
      isUploading: false,
      asynTime: null,
      selectBatchColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "key",
          width: 60,
        },
        {
          title: "批次名称",
          ellipsis: true,
          dataIndex: "batchName",
          width: 150,
        },
        {
          title: "有效号码",
          ellipsis: true,
          dataIndex: "phoneCount",
          width: 80,
        },
        {
          title: "移动",
          ellipsis: true,
          dataIndex: "cmccCount",
          width: 50,
        },
        {
          title: "联通",
          ellipsis: true,
          dataIndex: "cmcuCount",
          width: 50,
        },
        {
          title: "电信",
          ellipsis: true,
          dataIndex: "cmctCount",
          width: 50,
        },
        {
          title: "状态",
          ellipsis: true,
          dataIndex: "statusLabel",
          scopedSlots: { customRender: "status" },
          width: 100,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 120,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      selectBatchTable: [],
      selectBatchPagination: {},
      isSelectBatchNameLabel: false,
      selectBatchNameLabel: "",
      selectBatchShow: false,
      saveBatchModalShow: false,
      isSaveBatch: false,
      batchName: "",
      batchId: "",
      pooiForm: {
        validNumber: 0,
        moveNumber: 0,
        unicomNumber: 0,
        telecomNumber: 0,
      },
      saveBatchShow: false,
      isUploadBatch: false,
      batchImportShow: false,
      isCityDis: false,
      checkedTiming: false,
      fileList: [],
      msgName: "",
      platform: "",
      accessKey: undefined,
      userSmsAccountId: undefined,
      userLoginNameList: [],
      platformList: [],
      sign: undefined,
      signId: undefined,
      signList: [],
      textarea: "",
      isUpload: false,
      time: null,
      sendTime: "",
      SMStextareaLabel: "",
      blankNumberCheck: false,
      active: "1",
      strategyList: [],
      strategyId: undefined,
      numberOfBatchesCreated: 1,
      mode: process.env.VUE_APP_CURRENTMODE,
    };
  },
  computed: {
    isdetailsClick() {
      if (this.batchId) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getPlatformList(); // 获取平台
    this.getUserSmsAccount();
    this.getStrategyList();
  },
  methods: {
    callback() {
      this.textarea = "";
      this.strategyId = undefined;
    },
    getStrategyList(name) {
      this.$http.get("/contentStrategy/strategyList", { name }).then((res) => {
        if (res.result == 200) {
          this.strategyList = res.data;
        }
      });
    },
    userSearch(val) {
      this.getUserSmsAccount(val);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    filterOption1(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 点击使用模版
    smsContentTemplateClick() {
      this.smsContentTemplateShow = true;
    },
    // 关闭使用模版
    smsContentTemplateClose() {
      this.smsContentTemplateShow = false;
    },
    // 获取短信内容
    textarea_sms(val) {
      this.textarea = val;
    },
    // 点击批次详情
    detailsClick(operator) {
      this.batchDetailListShow = true;
      if (!operator) {
        this.operator = "";
      } else {
        this.operator = operator;
      }
      let data = {
        batchId: this.batchId,
        pageNo: 1,
        pageSize: 10,
        operator: this.operator,
      };
      this.batchDetailList(data);
    },
    // 关闭批次详情
    batchDetailListClose() {
      this.batchDetailListTable = [];
    },
    // 查询批次详情
    batchDetailList(data) {
      this.$http.get("/batchInfo/batchDetailList", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
          });
          this.batchDetailListTable = list;
          this.batchDetailListPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_batchList(current),
          };
        }
      });
    },
    // 批次详情分页
    currentPage_batchList(index) {
      let data = {
        batchId: this.batchId,
        pageNo: index,
        pageSize: 10,
        operator: this.operator,
      };
      this.batchDetailList(data);
    },
    // 选择精准发送
    preciseChange(val) {
      this.isPreciseCheck = val;
      if (!val) {
        this.operatorList = [];
      }
    },
    // 点击批量导入
    batchImport() {
      this.batchImportShow = true;
    },
    // 确定批量导入
    batchImport_submit() {
      const formData = new FormData();
      if (this.fileList && this.fileList.length > 0) {
        this.fileList.forEach((file) => {
          formData.append("file", file.originFileObj);
        });
      } else {
        this.$message.warning("请上传号码");
        return;
      }
      this.isUploadBatch = true;
      reqwest({
        url: this.$path + "/batchInfo/addBatchInfo",
        method: "post",
        processData: false,
        crossOrigin: true,
        withCredentials: true,
        data: formData,
        success: (res) => {
          this.msgName = this.fileList[0].name.split(".")[0];
          this.fileList = [];
          this.$message.success("上传成功！");
          this.isUploadBatch = false;
          this.batchImportShow = false;
          this.batchId = res.data.batchId;
          this.isUploading = true;
          this.batchInfoDetailAsyn(this.batchId);
        },
        error: (res) => {
          this.isUploadBatch = false;
          this.$message.error(JSON.parse(res.response).message);
        },
      });
    },
    // 刷新导入信息
    batchInfoDetailAsyn(batchId) {
      if (this.asynTime) {
        clearInterval(this.asynTime);
        this.asynTime = null;
      }
      this.asynTime = setInterval(() => {
        this.batchInfoDetail(batchId);
      }, 1000);
    },
    // 关闭批量导入
    batchImportClose() {
      this.fileList = [];
    },
    // 查询批次信息
    batchInfoDetail(batchId) {
      this.$http
        .get("/batchInfo/batchInfoDetail", { batchId: batchId })
        .then((res) => {
          if (res.result === 200) {
            this.pooiForm = {
              validNumber: res.data.phoneCount,
              moveNumber: res.data.cmccCount,
              unicomNumber: res.data.cmcuCount,
              telecomNumber: res.data.cmctCount,
            };
            if (res.data.status === "CREATED") {
              clearInterval(this.asynTime);
              this.asynTime = null;
              this.saveBatchShow = true;
              this.isUploading = false;
            }
          }
        });
    },
    // 点击保存批次
    saveBatch() {
      this.saveBatchModalShow = true;
    },
    // 确认保存批次
    saveBatch_submit() {
      let data = {
        batchId: this.batchId,
        batchName: this.batchName,
      };
      this.isSaveBatch = true;
      this.$http.json_post("/batchInfo/saveBatchInfo", data).then((res) => {
        this.isSaveBatch = false;
        if (res.result === 200) {
          this.$message.success("保存成功");
          this.saveBatchModalShow = false;
          this.saveBatchShow = false;
          let param = {
            pageNo: 1,
            pageSize: 10,
          };
          this.$http.get("/batchInfo/list", param).then((res) => {
            if (res.result === 200) {
              let list = res.data.records;
              this.selectBatchNameLabel = list[0].batchName;
              this.batchId = list[0].id;
              this.isSelectBatchNameLabel = true;
            }
          });
        }
      });
    },
    // 关闭保存批次
    saveBatchClose() {
      this.batchName = "";
    },
    // 查询批次列表
    batchInfoList(data) {
      this.$http.get("/batchInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          list.forEach((item, index) => {
            item.key = index + 1;
            switch (item.status) {
              case "CREATED":
                item.statusLabel = "创建完成";
                break;
              case "CREATEING":
                item.statusLabel = "创建中";
                break;
            }
            item.gmtCreated = this.$common.transformTime(item.gmtCreated);
          });
          this.selectBatchTable = list;
          this.selectBatchPagination = {
            showQuickJumper: true,
            showTotal: () => `共${res.data.total}条`,
            pageSize: res.data.pageSize,
            current: res.data.pageNo,
            total: res.data.total,
            onChange: (current) => this.currentPage_batch(current),
          };
        }
      });
    },
    // 批次列表分页
    currentPage_batch(index) {
      let data = {
        pageNo: index,
        pageSize: 10,
      };
      this.batchInfoList(data);
    },
    // 点击选择批次
    selectBatch() {
      this.selectBatchShow = true;
      let data = {
        pageNo: 1,
        pageSize: 10,
      };
      this.batchInfoList(data);
    },
    // 点击使用批次
    batchUse(val) {
      this.selectBatchNameLabel = val.batchName;
      this.batchId = val.id;
      this.isSelectBatchNameLabel = true;
      this.pooiForm = {
        validNumber: val.phoneCount,
        moveNumber: val.cmccCount,
        unicomNumber: val.cmcuCount,
        telecomNumber: val.cmctCount,
      };
      this.selectBatchShow = false;
    },
    // 关闭选择批次
    selectBatchClose() {
      this.selectBatchTable = [];
    },
    // 上传文件改变时限制文件数量只有1个
    handleChangeFileList(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.fileList = fileList;
    },
    // 搜索平台
    fetchUser(val) {
      this.getPlatformList(val);
    },
    // 获取焦点
    activityVideoFocus() {
      this.getPlatformList();
    },
    // 获取平台
    getPlatformList(val) {
      this.platformList = [];
      this.$http
        .get("/sms/user/platformList", { canSendBatchSms: true, name: val })
        .then((res) => {
          if (res.result === 200) {
            let list = res.data;
            list.forEach((item) => {
              this.platformList.push({
                label: item.name,
                value: item.accessKey,
                code: item.code,
                id: item.id,
              });
            });
            this.platform = "";
            this.accessKey = undefined;
            if (this.platformList.length > 0) {
              this.platform = this.platformList[0].code;
              this.accessKey = this.platformList[0].value;
            }
          }
        });
    },
    // 账号下拉框
    userSmsChange(val) {
      this.getSignList(val);
    },
    getSignList(val) {
      let data = {
        pageNo: 1,
        pageSize: 100,
        userSmsAccountId: val,
      };
      this.$http.get("/sms/userSignInfo/list", data).then((res) => {
        if (res.result === 200) {
          let list = res.data.records;
          let signList = [];
          list.forEach((item) => {
            signList.push({
              sign: "【" + item.sign.trim() + "】",
              label: item.sign,
              signId: item.id,
            });
          });
          this.signList = signList;
        }
      });
    },
    // 删除上传文件
    handleRemoveMale(file) {
      let index = this.fileList.indexOf(file);
      let newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    // 上传文件之前的钩子
    beforeUploadMale(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    // 选择平台
    platformChange(val) {
      this.accessKey = val;
      this.sign = undefined;
      let platformId = "";
      this.platformList.forEach((item) => {
        if (val === item.value) {
          this.platform = item.code;
          platformId = item.id;
        }
      });
    },
    // 选择签名
    sighChange(sign) {
      this.sign = sign;
      this.signList.forEach((item) => {
        if (item.sign === sign) {
          this.signId = item.signId;
        }
      });
    },
    // 确定定时发送
    sendTiming_submit() {
      if (this.checkedTiming) {
        if (this.time) {
          this.sendTime = this.$common.transformTime(this.time);
        }

        if (!this.sendTime) {
          this.$message.warning("时间不能为空！");
          return;
        }
      }
      if (!this.batchId) {
        this.$message.warning("请选择批次！");
        return;
      }
      if (!this.msgName) {
        this.$message.warning("活动名称不能为空！");
        return;
      }
      if (!this.sign) {
        this.$message.warning("签名不能为空！");
        return;
      }
      if (this.active == "1" && !this.textarea) {
        this.$message.warning("短信内容不能为空！");
        return;
      }
      if (this.active == "2" && !this.strategyId) {
        this.$message.warning("模板不能为空！");
        return;
      }
      let content = this.sign + this.textarea;
      let data = {
        batchId: this.batchId,
        signId: this.signId,
        content: content,
        activityCode: this.msgName,
        userSmsAccountId: this.userSmsAccountId,
        blankNumberCheck: this.blankNumberCheck,
      };
      if (this.active == "2") {
        data.strategyId = this.strategyId;
      }
      if (this.operatorList.length > 0) {
        data.operatorList = this.operatorList;
      }
      if (this.sendTime) {
        data.sendTime = this.sendTime;
      }
      this.isUpload = true;
      let title = "提交审核, 是否继续?";
      this.$confirm({
        title: "提示",
        content: title,
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          if (
            typeof (this.numberOfBatchesCreated * 1) == "number" &&
            this.numberOfBatchesCreated * 1 > 1
          ) {
            let numberOfBatchesCreated = this.numberOfBatchesCreated * 1;
            const step =
              (numberOfBatchesCreated - 1) / (numberOfBatchesCreated - 1);
            let list = Array.from(
              { length: numberOfBatchesCreated },
              (_, i) => 1 + i * step
            );
            this.$axios
              .all(
                list.map((key) => {
                  let params = {
                    ...data,
                  };
                  params.activityCode = this.msgName + "-" + key;
                  return this.$http.json_post(
                    "/sms/addMsgPlanForFileUpload",
                    params
                  );
                })
              )
              .then((resArr) => {
                this.fileList = [];
                this.$message.success("提交审核成功！");
                this.isUpload = false;
                this.textarea = "";
                this.msgName = "";
                this.$router.push({ path: "/smmSmarketingRecords" });
              })
              .catch((err) => {
                this.isUpload = false;
              });
          } else {
            this.$http
              .json_post("/sms/addMsgPlanForFileUpload", data)
              .then((res) => {
                if (res.result === 200) {
                  this.fileList = [];
                  this.$message.success("提交审核成功！");
                  this.isUpload = false;
                  this.textarea = "";
                  this.msgName = "";
                  this.$router.push({ path: "/smmSmarketingRecords" });
                }
              })
              .catch((err) => {
                this.isUpload = false;
              });
          }
        },
        onCancel: () => {
          this.isUpload = false;
        },
        class: "test",
      });
    },
    // 关闭定时发送
    sendTimingClose() {
      this.time = null;
      this.sendTime = "";
    },
    // 查询账号
    getUserSmsAccount(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        querySingleUser: true,
        accessKeyOrName,
      };
      this.$http
        .get("/userPlatformRelation/list", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data.records;
            this.userLoginNameList = list;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
  },
  beforeDestroy() {
    if (this.asynTime) {
      clearInterval(this.asynTime);
      this.asynTime = null;
    }
  },
};
</script>
<style>
.ant-tooltip {
  max-width: 1000px !important;
  font-size: 12px !important;
}
</style>
<style scoped lang="scss">
.smsmarketing {
  display: flex;
}
.right {
  margin-top: 115px;
  margin-left: 50px;
  width: 240px;
  height: 450px;
  background: url("../assets/phone.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 40px 20px;
  .bubble {
    padding: 10px;
    width: 200px;
    border-radius: 10px;
    background-color: #f0f0f0;
    position: relative;
    overflow-wrap: break-word;
  }
  .bubble::before {
    position: absolute;
    top: 40%;
    left: -20px;
    content: "";
    width: 0;
    height: 0;
  }
}
.operatorList-checkbox {
  margin-left: 15px;
}
.dropbox {
  margin-bottom: 10px;
  // width: 360px;
  .box-btn {
    margin-bottom: 16px;
    margin-right: 20px;
  }
  .btn-color {
    background: #e6a23c;
    color: #ffffff;
  }
  .dropbox-ts {
    font-size: 12px;
    color: #606266;
    margin-top: 7px;
    display: inline-block;
  }
  .number-pool {
    width: 590px;
    height: 100px;
    background: #f8f9f9;
    border: 1px solid #f2f2f2;
    padding: 16px;
    margin-bottom: 5px;
    display: flex;
    text-align: center;
    justify-content: space-around;
    position: relative;
    .spin-pool {
      position: absolute;
      width: 590px;
      height: 130px;
      text-align: center;
      top: 0px;
      left: 0px;
      padding: 50px;
      background: rgba(248, 249, 249, 0.8);
    }
    .number-pool-item {
      color: #848a9f;
      .number {
        font-size: 22px;
        line-height: 65px;
      }
    }
  }
}
.title-number-pool {
  font-size: 12px;
  margin-bottom: 10px;
}
.word {
  display: block;
  width: 100%;
  line-height: 40px;
  text-align: right;
  font-style: normal;
  font-size: 12px;
  .template-sign {
    position: absolute;
    bottom: -4px;
    left: 0px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .template2 {
    position: absolute;
    bottom: -4px;
    left: 70px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
  .template3 {
    position: absolute;
    bottom: -4px;
    left: 120px;
    font-size: 13px;
    margin-bottom: 5px;
    margin-right: 10px;
  }
}
.word i {
  color: red;
  font-style: normal;
}
.title {
  font-weight: bold;
}
.select-tag {
  width: 350px;
  margin-bottom: 10px;
}
.sign-input {
  margin-bottom: 10px;
  .ant-input-affix-wrapper {
    width: 350px;
  }
  .ant-input {
    width: 350px;
  }
}
.smsContent {
  width: 590px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;

  .template {
    font-size: 14px;
    margin-bottom: 5px;
    color: #3a8ee6;
    text-align: right;
  }

  .editor {
    position: absolute;
    top: -6px;
    left: 70px;
    color: #3a8ee6;
    font-size: 14px;
  }
}
</style>
