<template>
  <div class="preSubmitAudit">
    <div class="searchCondition">
      <div class="searchConditionItem">
        签名：
        <a-input v-model="sign" placeholder="请输入签名" allowClear />
      </div>
      <div class="searchConditionItem">
        创建时间：
        <a-date-picker
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          v-model="startTime"
          placeholder="开始日期"
          @openChange="handleGmtCreatedStartOpenChange"
        />
        <i style="display: inline-block; margin-left: 20px"></i>
        <a-date-picker
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="结束日期"
          v-model="endTime"
          :open="gmtEndOpen"
          @openChange="handleGmtCreatedEndOpenChange"
        />
      </div>
      <div class="searchConditionItem">
        状态：
        <a-select
          v-model="status"
          placeholder="状态"
          :filterOption="false"
          allowClear
        >
          <a-select-option
            v-for="i in statusList"
            :key="i.key"
            :value="i.key"
            >{{ i.value }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchConditionItem">
        运营商：
        <a-select v-model="phoneOperator" placeholder="请选择运营商" allowClear>
          <a-select-option
            v-for="i in phoneOperatorList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="searchButton">
        <a-button type="primary" @click="inquire(1)">查询</a-button>
        <a-dropdown :disabled="selectedRowKeys.length == 0">
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="examine()">批量审核</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="batchDelete">批量删除</a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="batchUpdateRetryGroup()">
                批量设置重提分组
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(tableColumns)"
      :columns="tableColumns"
      :dataSource="tableDataSource"
      :pagination="false"
      size="small"
      :rowKey="(record) => record.id"
      :row-selection="{
        onChange: onSelectChange,
        selectedRowKeys: selectedRowKeys,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === '汇总',
            name: record.name,
          },
        }),
      }"
    >
      <span
        v-if="record.name !== '汇总'"
        slot="channelSmsAccountName"
        slot-scope="text, record"
      >
        <a-tooltip placement="topLeft">
          <template slot="title">
            {{ text }}({{ record.channelSmsAccountAccessKey }})
          </template>
          {{ text }}({{ record.channelSmsAccountAccessKey }})
        </a-tooltip>
      </span>
      <span
        v-if="record.name !== '汇总'"
        slot="example"
        slot-scope="text, record"
      >
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span v-html="text"> </span>
          </template>
          <span v-html="text"> </span>
        </a-tooltip>
      </span>
      <span
        v-if="record.name !== '汇总'"
        slot="status"
        slot-scope="text, record"
      >
        <a-tooltip placement="topLeft" v-if="text === 'DELAY_AUTO_PASSED'">
          <template slot="title"> 执行时间: {{ record.autoAuditAt }} </template>
          <a-tag color="orange"> 定时通过 </a-tag>
        </a-tooltip>
        <a-tooltip
          placement="topLeft"
          v-else-if="text === 'DELAY_AUTO_FAILURE'"
        >
          <template slot="title"> 执行时间: {{ record.autoAuditAt }} </template>
          <a-tag color="orange"> 定时驳回 </a-tag>
        </a-tooltip>
        <a-tag v-else-if="text === 'AUTO_PASSED'" color="green">
          自动通过
        </a-tag>
        <a-tag v-else-if="text === 'AUTO_FAILURE'" color="red">
          自动失败
        </a-tag>
        <a-tag v-else-if="text === 'AUTO_BLOCKED'" color="orange">
          待审核
        </a-tag>
        <a-tag v-else-if="text === 'MODIFYING'" color="orange"> 修改中 </a-tag>
      </span>
      <span
        v-if="record.name !== '汇总'"
        slot="failureResetCount"
        slot-scope="text, record"
      >
        <a-tag v-if="text == 0" color="orange"> 未重提 </a-tag>
        <a-tag v-if="text > 0" color="green"> 已重提 </a-tag>
      </span>
      <span
        v-if="record.name !== '汇总'"
        slot="retryGroupName"
        slot-scope="text, record"
      >
        {{ record.retryGroupName ? record.retryGroupName : "无" }}

        <a-tooltip placement="topLeft" v-if="record.autoResendFailureDisabled">
          <template slot="title">
            <div>失败自动重提已关闭</div>
          </template>
          <icon-font type="icon-lianxi2hebing_jinzhi" />
        </a-tooltip>
        <a-button
          v-else
          type="link"
          size="small"
          icon="edit"
          @click="updateRetryGroup(record)"
        />
      </span>
      <span
        slot="totalCount"
        slot-scope="text, record"
        style="display: inline-block; width: 100%"
      >
        <div>
          <div style="float: left" v-if="record.name == '汇总'">
            汇总：
            <a-tooltip placement="topLeft">
              <template slot="title"> 只对当前页面生效 </template>
              <a-icon type="question-circle" style="color: #3d91f7" />
            </a-tooltip>
          </div>
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>总条数:{{ record.totalCount }}</div>
            </template>
            <a
              href="javascript:;"
              @click="numClick(record, '')"
              class="send-color-total"
              >{{ record.totalCount }}</a
            >
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                等待提交条数:{{ record.waitSubmitCount }} |
                {{ record.waitSubmitRate }}
              </div>
            </template>
            <a
              href="javascript:;"
              @click="numClick(record, 'WAIT_SUBMIT')"
              class="send-color-waitSubmit"
              >{{ record.waitSubmitCount }}</a
            >
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                发送成功条数:{{ record.successCount }} |
                {{ record.successRate }}
              </div>
            </template>
            <a
              href="javascript:;"
              @click="numClick(record, 'SUCCESS')"
              class="send-color-success"
              >{{ record.successCount }}</a
            >
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                等待回执条数:{{ record.waitCallbackCount }} |
                {{ record.waitCallbackRate }}
              </div>
            </template>
            <a
              href="javascript:;"
              @click="numClick(record, 'SUBMIT_SUCCESS')"
              class="send-color-waitCallback"
              >{{ record.waitCallbackCount }}</a
            >
          </a-tooltip>
          <a-divider type="vertical" />
          <a-tooltip placement="topLeft">
            <template slot="title">
              <div>
                发送失败条数:{{ record.failureCount }} |
                {{ record.failureRate }}
              </div>
            </template>
            <a
              href="javascript:;"
              @click="numClick(record, 'FAILURE')"
              class="send-color-fail"
              >{{ record.failureCount }}</a
            >
          </a-tooltip>
        </div>
      </span>
      <span
        v-if="record.name !== '汇总'"
        slot="action"
        slot-scope="text, record"
        style="width: 100%"
      >
        <a-button
          type="link"
          size="small"
          @click="examine(record)"
          :disabled="
            record.waitSubmitCount == 0 || record.status == 'MODIFYING'
          "
          >审核</a-button
        >
        <a-divider type="vertical" />
        <a-button
          type="link"
          size="small"
          :disabled="record.waitSubmitCount == 0"
          @click="changeChannelSmsAccount(record)"
          >修改通道</a-button
        >
        <a-divider type="vertical" />
        <a-dropdown :trigger="['click']">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            其它 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a
                href="javascript:;"
                @click="resetFailureStatus(record)"
                :disabled="record.status !== 'AUTO_BLOCKED'"
              >
                失败状态重置
              </a>
            </a-menu-item>
            <a-menu-item>
              <a href="javascript:;" @click="getChannelSendReport(record)">
                通道分析
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="getChannelRetrySendQueuePage(1, record)"
              >
                读取重试队列
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="updateAutoResendFailurePolicy(record)"
              >
                {{ record.autoResendFailureDisabled ? "开启" : "关闭" }}自动重提
              </a>
            </a-menu-item>
            <a-menu-item>
              <a
                href="javascript:;"
                @click="deleteClick(record)"
                style="color: red"
              >
                删除
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>

      <span slot="footer">
        <div class="pagination">
          <my-pagination
            :tableList="tableDataSource"
            :total="total"
            :pageNo="currentPage"
            :pageSize="pageSize"
            summaryShow
            @changePage="changePage"
          ></my-pagination>
        </div>
      </span>
    </a-table>
    <!-- 条数 -->
    <a-modal
      :title="title + '条数'"
      v-model="numShow"
      :maskClosable="false"
      class="actionSub-class"
      width="1200px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          planId：
          <a-select
            v-model="planId"
            placeholder="请选择planId"
            allowClear
            showSearch
            @change="getItemList(1)"
          >
            <a-select-option v-for="id in planIdList" :key="id" :value="id">
              {{ id }}
            </a-select-option>
          </a-select>
        </div>
        <div class="searchConditionItem">
          客户编号：
          <a-input
            v-model="customerCode"
            placeholder="请输入客户编号"
            allowClear
            @keyup.enter="getItemList(1)"
          />
        </div>
        <div class="searchConditionItem">
          通道：
          <a-select
            v-model="channelSmsAccountId"
            placeholder="请选择运营商通道"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
            @change="getItemList(1)"
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled || item.connectionNum <= 0"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchConditionItem">
          提交时间：
          <a-range-picker
            v-model="submitTime"
            value-format="YYYY-MM-DD HH:mm:ss"
            format="YYYY-MM-DD HH:mm:ss"
            showTime
            style="width: 250px"
            @change="getItemList(1)"
          />
        </div>
        <div class="searchConditionItem">
          状态：
          <a-select
            v-model="sendStatus"
            placeholder="请选择状态"
            @change="getItemList(1)"
          >
            <a-select-option
              v-for="(item, index) in sendStatusList"
              :key="index"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="getItemList(1)">查询</a-button>
          <a-button
            type="primary"
            @click="numItemResetFailureStatus()"
            :disabled="sendStatus !== 'FAILURE'"
          >
            失败重置
          </a-button>
          <a-button
            type="primary"
            @click="batchReject()"
            :disabled="sendStatus !== 'WAIT_SUBMIT'"
          >
            批量驳回
          </a-button>
        </div>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(numColumns)"
        :columns="numColumns"
        :dataSource="numTableData"
        :pagination="numPagination"
        :loading="loadingNumTable"
        size="small"
        :rowKey="(record) => record.id"
      >
        <span slot="sendStatus" slot-scope="text" style="width: 100%">
          <a-tag v-if="text === 'WAIT_SUBMIT'" color="orange"> 等待提交 </a-tag>
          <a-tag v-if="text === 'SUCCESS'" color="green"> 发送成功 </a-tag>
          <a-tag v-if="text === 'FAILURE'" color="red"> 发送失败 </a-tag>
          <a-tag v-if="text === 'SUBMIT_SUCCESS'" color="orange">
            提交成功
          </a-tag>
          <a-tag v-if="text === 'SUBMITTING'" color="orange"> 提交中 </a-tag>
          <a-tag v-if="text === 'PRE_SUBMIT_SUCCESS'" color="orange">
            预提交成功
          </a-tag>
        </span>
        <span slot="content" slot-scope="text">
          <a-tooltip placement="topLeft">
            <template slot="title">
              {{ text }}
            </template>
            {{ text }}
          </a-tooltip>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="numShow = false">关闭</a-button>
        </div>
      </template>
    </a-modal>
    <!-- 审核 -->
    <a-modal
      :title="auditTitle"
      v-if="auditShow"
      v-model="auditShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="驳回数量限制" v-if="batchRejectShow">
          <a-switch
            checked-children="部分驳回"
            un-checked-children="全部驳回"
            v-model="countShow"
          />
          <i style="display: inline-block; margin-right: 20px"></i>
          <a-input
            v-model="count"
            v-if="countShow"
            placeholder="请输入条数限制"
            style="width: 180px"
          />
        </a-form-item>
        <template v-else>
          <a-form-item label="状态:">
            <a-radio-group v-model="statusModal" :options="options" />
          </a-form-item>
          <a-form-item>
            <span slot="label">
              发送条数限制
              <a-tooltip placement="topLeft">
                <template slot="title">
                  发送达到指定条数，自动变更为待审核状态
                </template>
                <a-icon type="question-circle" style="color: #3d91f7" />
              </a-tooltip>
            </span>
            <a-switch v-model="countShow" />
            <i style="display: inline-block; margin-right: 20px"></i>
            <a-input
              v-model="count"
              v-if="countShow"
              placeholder="请输入条数限制"
              style="width: 200px"
            />
          </a-form-item>
          <a-form-item label="定时审核:">
            <a-checkbox @change="checkChange" v-model="autoAuditFlag">
            </a-checkbox>
            <a-date-picker
              v-if="autoAuditFlag"
              v-model="autoAuditAt"
              style="margin-left: 10px"
              show-time
              placeholder="请选择时间"
              format="YYYY-MM-DD HH:mm:ss"
              value-format="YYYY-MM-DD HH:mm:ss"
            />
          </a-form-item>
        </template>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="auditShow = false">关闭</a-button>
          <a-button
            type="primary"
            @click="batch_table_submit"
            v-if="batchExamine"
            >确定</a-button
          >
          <a-button type="primary" @click="table_submit(false)" v-else
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="修改底层通道"
      v-model="changeChannelSmsAccountShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="通道:">
          <a-select
            v-model="channelSmsAccountId"
            placeholder="请选择运营商通道"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled || item.connectionNum <= 0"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <h4>注意: 修改通道会直接修改批次预提交数据</h4>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="changeChannelSmsAccountShow = false"
            >关闭</a-button
          >
          <a-button type="primary" @click="changeChannelSmsAccountSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="通道分析"
      v-model="channelSendReportShow"
      :maskClosable="false"
      class="actionSub-class"
      width="800px"
    >
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(channelSendReportTableColumns)"
        :columns="channelSendReportTableColumns"
        :dataSource="channelSendReportTableData"
        :pagination="false"
        size="small"
        :rowKey="(record) => record.key"
      >
        <span
          slot="totalCount"
          slot-scope="text, record"
          style="display: inline-block; width: 100%"
        >
          <div>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>总条数:{{ record.totalCount }}</div>
              </template>
              <a href="javascript:;" class="send-color-total">
                {{ record.totalCount }}
              </a>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>
                  等待提交条数:{{ record.waitSubmitCount }} |
                  {{ record.waitSubmitProportion }}
                </div>
              </template>
              <a href="javascript:;" class="send-color-waitSubmit">
                {{ record.waitSubmitCount }}
              </a>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>
                  发送成功条数:{{ record.successCount }} |
                  {{ record.successProportion }}
                </div>
              </template>
              <a href="javascript:;" class="send-color-success">
                {{ record.successCount }}
              </a>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>
                  等待回执条数:{{ record.waitCallbackCount }} |
                  {{ record.waitCallbackProportion }}
                </div>
              </template>
              <a href="javascript:;" class="send-color-waitCallback">
                {{ record.waitCallbackCount }}
              </a>
            </a-tooltip>
            <a-divider type="vertical" />
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>
                  发送失败条数:{{ record.failureCount }} |
                  {{ record.failureProportion }}
                </div>
              </template>
              <a href="javascript:;" class="send-color-fail">
                {{ record.failureCount }}
              </a>
            </a-tooltip>
          </div>
        </span>
        <span slot="successRateTitle">
          成功率
          <a-tooltip>
            <template slot="title">
              成功率 = 发送成功数 / ( 发送成功数 + 发送失败数 + 等待回执数 )
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </span>
        <span slot="clickUvRateTitle">
          点击率
          <a-tooltip>
            <template slot="title"> 点击率 = 点击UV / 发送成功数 </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </span>
        <span slot="clickUvRate" slot-scope="text, record">
          <div>
            <a-tooltip placement="topLeft">
              <template slot="title">
                <div>点击数:{{ record.clickUv }}</div>
              </template>
              {{ text }}
            </a-tooltip>
          </div>
        </span>
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="channelSendReportShow = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      :title="updateRetryGroupTitle"
      v-model="updateRetryGroupShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }">
        <a-form-item label="失败重提分组:">
          <a-select
            v-model="retryGroupId"
            placeholder="请选择失败重提分组"
            showSearch
            option-filter-prop="children"
          >
            <a-select-option
              v-for="item in channelRetryGroupList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="updateRetryGroupShow = false"
            >关闭</a-button
          >
          <a-button type="primary" @click="updateRetryGroupSubmit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>

    <a-modal
      title="重试队列"
      v-model="channelRetrySendQueuePageShow"
      :maskClosable="false"
      class="actionSub-class"
      width="1200px"
    >
      <div class="searchCondition">
        <div class="searchConditionItem">
          客户编号：
          <a-input
            v-model="customerCode"
            placeholder="请输入客户编号"
            allowClear
            @keyup.enter="getChannelRetrySendQueuePage(1)"
            style="width: 240px; margin-right: 10px"
          />
        </div>
        <div class="searchConditionItem">
          通道：
          <a-select
            v-model="channelSmsAccountId"
            placeholder="请选择运营商通道"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
            @change="getChannelRetrySendQueuePage(1)"
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled || item.connectionNum <= 0"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchConditionItem">
          重提通道：
          <a-select
            v-model="channelSmsAccountId"
            placeholder="请选择运营商通道"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
            @change="getChannelRetrySendQueuePage(1)"
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled || item.connectionNum <= 0"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchConditionItem">
          原始通道：
          <a-select
            v-model="originalChannelSmsAccountId"
            placeholder="请选择运营商通道"
            allowClear
            :filterOption="false"
            @search="searchSmsAccountList"
            showSearch
            @change="getChannelRetrySendQueuePage(1)"
          >
            <a-select-option
              v-for="item in smsAccountList"
              :key="item.id"
              :value="item.id"
              :disabled="!item.enabled || item.connectionNum <= 0"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </div>
        <div class="searchButton">
          <a-button type="primary" @click="getChannelRetrySendQueuePage(1)">
            查询
          </a-button>
        </div>
      </div>
      <a-table
        bordered
        :rowClassName="$common.rowClassColor"
        :components="$common.getTitle(channelRetrySendQueuePageColumns)"
        :columns="channelRetrySendQueuePageColumns"
        :dataSource="channelRetrySendQueuePageTableData"
        :pagination="channelRetrySendQueuePagePagination"
        :loading="loadingChannelRetrySendQueuePageTable"
        size="small"
        :rowKey="(record) => record.id"
      >
      </a-table>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="channelRetrySendQueuePageShow = false"
            >关闭</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import myPagination from "../tool/myPagination.vue";

export default {
  name: "preSubmitAudit",
  components: {
    myPagination,
  },
  data() {
    return {
      sign: "",
      tableColumns: [
        {
          title: "编号",
          align: "center",
          dataIndex: "id",
          ellipsis: true,
          width: 80,
          fixed: "left",
        },
        {
          title: "日期",
          dataIndex: "date",
          ellipsis: true,
          width: 80,
          align: "center",
        },
        {
          title: "签名",
          dataIndex: "sign",
          ellipsis: true,
          width: 80,
          align: "center",
        },
        {
          title: "运营商",
          dataIndex: "phoneOperator",
          ellipsis: true,
          align: "center",
          width: 80,
        },
        {
          title: "初始通道",
          dataIndex: "channelSmsAccountName",
          scopedSlots: { customRender: "channelSmsAccountName" },
          ellipsis: true,
          width: 225,
        },
        {
          title: "内容",
          dataIndex: "example",
          scopedSlots: { customRender: "example" },
          ellipsis: true,
          width: 240,
        },
        {
          title: "状态",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
          ellipsis: true,
          width: 100,
        },
        {
          title: "重提状态",
          dataIndex: "failureResetCount",
          scopedSlots: { customRender: "failureResetCount" },
          ellipsis: true,
          width: 100,
        },
        {
          title: "失败重提分组",
          dataIndex: "retryGroupName",
          scopedSlots: { customRender: "retryGroupName" },
          ellipsis: true,
          width: 200,
        },
        {
          title: "条数",
          dataIndex: "totalCount",
          scopedSlots: { customRender: "totalCount" },
          ellipsis: true,
          width: 450,
          align: "center",
        },
        {
          title: "创建时间",
          dataIndex: "gmtCreated",
          ellipsis: true,
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          fixed: "right",
          width: 180,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableDataSource: [],
      total: 0,
      loadingTable: false,
      id: "",
      numShow: false,
      numColumns: [
        {
          title: "planId",
          dataIndex: "planId",
        },
        {
          title: "客户编号",
          dataIndex: "customerCode",
        },
        {
          title: "内容",
          dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "状态",
          dataIndex: "sendStatus",
          scopedSlots: { customRender: "sendStatus" },
        },
        {
          title: "通道名称",
          dataIndex: "channelSmsAccountName",
        },
        {
          title: "运营商",
          dataIndex: "phoneOperator",
        },
        {
          title: "区域",
          dataIndex: "province",
        },
        {
          title: "提交时间",
          dataIndex: "submitTime",
        },
      ],
      numTableData: [],
      numPagination: {},
      loadingNumTable: false,
      submitTime: [],
      sendStatus: "",
      sendStatusList: [
        { label: "等待提交", value: "WAIT_SUBMIT" },
        { label: "发送成功", value: "SUCCESS" },
        { label: "发送失败", value: "FAILURE" },
        { label: "提交成功", value: "SUBMIT_SUCCESS" },
        { label: "全部", value: "" },
      ],
      numPage: 1,
      gmtEndOpen: false,
      startTime: moment().format("YYYY-MM-DD"),
      endTime: moment().format("YYYY-MM-DD"),
      status: undefined,
      statusList: [
        { key: "DELAY_AUTO_PASSED", value: "定时通过" },
        { key: "DELAY_AUTO_FAILURE", value: "定时驳回" },
        { key: "AUTO_PASSED", value: "自动通过" },
        { key: "AUTO_FAILURE", value: "自动失败" },
        { key: "AUTO_BLOCKED", value: "待审核" },
        { key: "MODIFYING", value: "修改中" },
      ],
      phoneOperator: undefined,
      phoneOperatorList: [
        {
          value: "中国移动",
          label: "中国移动",
        },
        {
          value: "中国联通",
          label: "中国联通",
        },
        {
          value: "中国电信",
          label: "中国电信",
        },
      ],
      options: [
        { label: "待审核", value: "AUTO_BLOCKED" },
        { label: "通过", value: "AUTO_PASSED" },
        { label: "驳回", value: "AUTO_FAILURE", disabled: false },
      ],
      auditTitle: "",
      auditShow: false,
      batchRejectShow: false,
      statusModal: "AUTO_BLOCKED",
      originalStatus: "",
      autoAuditFlag: false,
      autoAuditAt: null,
      tablePage: 1,
      currentPage: 1,
      pageSize: 10,
      timer: null,
      countShow: false,
      count: 100,
      deleteCheck: true,
      customerCode: "",
      planId: "",
      selectedRowKeys: [],
      selectedRows: [],
      batchExamine: false,
      title: "",
      preSubmitAuditTemplateId: "",
      channelSmsAccountId: undefined,
      searchSmsAccountTimer: null,
      smsAccountList: [],
      changeChannelSmsAccountShow: false,
      syncLoading: null,
      channelSendReportShow: false,
      channelSendReportTableData: [],
      channelSendReportTableColumns: [
        {
          title: "通道名称",
          dataIndex: "channelSmsAccountName",
        },
        {
          title: "条数",
          dataIndex: "totalCount",
          scopedSlots: { customRender: "totalCount" },
          ellipsis: true,
          width: 300,
          align: "center",
        },
        {
          dataIndex: "successRate",
          ellipsis: true,
          width: 100,
          scopedSlots: { title: "successRateTitle" },
          align: "center",
        },
        {
          dataIndex: "clickUvRate",
          ellipsis: true,
          width: 100,
          scopedSlots: {
            title: "clickUvRateTitle",
            customRender: "clickUvRate",
          },
          align: "center",
        },
      ],
      channelRetryGroupList: [],
      retryGroupId: -1,
      updateRetryGroupShow: false,
      updateRetryGroupTitle: "",
      channelRetrySendQueuePagePageNo: 1,
      loadingChannelRetrySendQueuePageTable: false,
      channelRetrySendQueuePageShow: false,
      channelRetrySendQueuePageTableData: [],
      channelRetrySendQueuePageColumns: [
        {
          title: "编号",
          dataIndex: "id",
          width: 90,
        },
        {
          title: "客户编号",
          dataIndex: "customerCode",
          width: 100,
        },
        {
          title: "重提通道",
          dataIndex: "channelSmsAccountName",
          width: 180,
          ellipsis: true,
        },
        {
          title: "原始通道",
          dataIndex: "originalChannelSmsAccountName",
          width: 180,
          ellipsis: true,
        },
        {
          title: "短信内容",
          dataIndex: "msgContent",
          width: 320,
          ellipsis: true,
        },
        {
          title: "失败原因",
          dataIndex: "failureStatus",
          width: 120,
          ellipsis: true,
        },
        {
          title: "重提时间",
          dataIndex: "retryAt",
          width: 150,
        },
      ],
      channelRetrySendQueuePagePagination: {},
      originalChannelSmsAccountId: undefined,
      planIdList: [],
    };
  },
  mounted() {
    this.getSmsAccountList();
    this.inquire(1);
    this.timer = setInterval(() => {
      this.inquire();
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); //关闭定时器
    }
  },
  methods: {
    // 点击查询
    inquire(index, pageSize) {
      if (index) {
        this.currentPage = index;
      }
      if (pageSize) {
        this.pageSize = pageSize;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        sign: this.sign,
        gmtCreatedStart: this.startTime,
        gmtCreatedEnd: this.endTime,
        status: this.status,
        phoneOperator: this.phoneOperator,
      };
      if (
        this.sign.indexOf(" ") !== -1 ||
        this.sign.indexOf(",") !== -1 ||
        this.sign.indexOf("，") !== -1
      ) {
        data.sign = "";
        data.signs = this.sign;
      }
      if (data.gmtCreatedStart) {
        data.gmtCreatedStart = data.gmtCreatedStart + " 00:00:00";
      }
      if (data.gmtCreatedEnd) {
        data.gmtCreatedEnd = data.gmtCreatedEnd + " 23:59:59";
      }

      this.loadingTable = true;
      this.$http
        .get("/preSubmitAuditTemplate/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            let summary = {
              name: "汇总",
              totalCount: 0,
              waitSubmitCount: 0,
              successCount: 0,
              waitCallbackCount: 0,
              failureCount: 0,
              waitSubmitRate: "0%",
              successRate: "0%",
              waitCallbackRate: "0%",
              failureRate: "0%",
            };
            list.forEach((item) => {
              item.waitSubmitRate = "0%";
              item.successRate = "0%";
              item.waitCallbackRate = "0%";
              item.failureRate = "0%";
              if (item.totalCount > 0) {
                item.waitSubmitRate =
                  ((item.waitSubmitCount / item.totalCount) * 100).toFixed(2) +
                  "%";
                item.successRate =
                  ((item.successCount / item.totalCount) * 100).toFixed(2) +
                  "%";
                item.waitCallbackRate =
                  ((item.waitCallbackCount / item.totalCount) * 100).toFixed(
                    2
                  ) + "%";
                item.failureRate =
                  ((item.failureCount / item.totalCount) * 100).toFixed(2) +
                  "%";
              }
              summary.totalCount += item.totalCount;
              summary.waitSubmitCount += item.waitSubmitCount;
              summary.successCount += item.successCount;
              summary.waitCallbackCount += item.waitCallbackCount;
              summary.failureCount += item.failureCount;
            });

            if (summary.totalCount > 0) {
              summary.waitSubmitRate =
                ((summary.waitSubmitCount / summary.totalCount) * 100).toFixed(
                  2
                ) + "%";
              summary.successRate =
                ((summary.successCount / summary.totalCount) * 100).toFixed(2) +
                "%";
              summary.waitCallbackRate =
                (
                  (summary.waitCallbackCount / summary.totalCount) *
                  100
                ).toFixed(2) + "%";
              summary.failureRate =
                ((summary.failureCount / summary.totalCount) * 100).toFixed(2) +
                "%";
            }
            list.push(summary);
            this.tableDataSource = list;
            this.total = res.data.total;
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    changePage(pageNo, pageSize) {
      this.inquire(pageNo, pageSize);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 审核
    examine(val) {
      if (val) {
        this.id = val.id;
        this.statusModal = val.status;
        this.originalStatus = val.status;
        this.deleteCheck = val.autoDelete;
        if (val.sendCountLimit !== -1) {
          this.countShow = true;
          this.count = val.sendCountLimit;
        } else {
          this.countShow = false;
        }

        if (
          val.status == "DELAY_AUTO_PASSED" ||
          val.status == "DELAY_AUTO_FAILURE"
        ) {
          if (val.status == "DELAY_AUTO_PASSED") {
            this.statusModal = "AUTO_PASSED";
          } else {
            this.statusModal = "AUTO_FAILURE";
          }
          this.autoAuditFlag = true;
        } else {
          this.autoAuditFlag = false;
        }

        if (this.statusModal == "AUTO_PASSED") {
          this.$set(this.options[2], "disabled", true);
        } else {
          this.$set(this.options[2], "disabled", false);
        }
        this.autoAuditAt = val.autoAuditAt;
        this.batchExamine = false;
        this.auditTitle = "审核";
      } else {
        this.statusModal = "AUTO_BLOCKED";
        this.deleteCheck = true;
        this.countShow = false;
        this.count = "";
        this.batchExamine = true;
        this.autoAuditFlag = false;
        this.autoAuditAt = null;
        this.auditTitle = "批量审核";
      }
      this.batchRejectShow = false;
      this.auditShow = true;
    },
    batchReject() {
      this.countShow = false;
      this.statusModal = "AUTO_FAILURE";
      this.count = "";
      this.batchRejectShow = true;
      this.auditTitle = "批量驳回";
      this.auditShow = true;
    },
    checkChange() {
      if (this.autoAuditFlag === false) {
        this.autoAuditAt = null;
      }
    },
    batch_table_submit() {
      if (this.statusModal == "AUTO_FAILURE") {
        let hasAUTO_PASSED = this.selectedRows.some((item) => {
          return item.stats == "AUTO_PASSED";
        });
        if (hasAUTO_PASSED) {
          this.$message.error("自动通过状态不能直接变更为驳回");
          return;
        }
      }
      this.selectedRows.forEach((item) => {
        this.table_submit(false, item);
      });
      this.$message.success("操作成功");
      this.selectedRowKeys.splice(0);
      this.selectedRows.splice(0);
      this.auditShow = false;
    },
    // 前往表格
    table_submit(ignoreRepeatCheck, item) {
      let data = {
        id: this.id,
        status: this.statusModal,
        originalStatus: this.originalStatus,
        sendCountLimit: -1,
      };
      if (this.batchRejectShow) {
        data.customerCode = this.customerCode;
        data.planId = this.planId;
        data.channelSmsAccountId = this.channelSmsAccountId;
        data.submitTimeStart = this.submitTime[0];
        data.submitTimeEnd = this.submitTime[1];
      } else {
        data.autoAuditAt = this.autoAuditAt;
        data.autoAuditFlag = this.autoAuditFlag;
        data.autoDelete = this.deleteCheck;
        data.ignoreRepeatCheck = ignoreRepeatCheck;
      }
      if (item) {
        data.id = item.id;
        data.originalStatus = item.status;
      }
      if (this.countShow) {
        data.sendCountLimit = this.count;
      }
      this.$http
        .json_post("/preSubmitAuditTemplate/changeStatus", data)
        .then((res) => {
          if (res.result === 200) {
            if (!this.batchExamine) {
              this.$message.success("操作成功");
              this.auditShow = false;
              if (!this.batchRejectShow) {
                this.inquire(1);
              }
            }
          } else if (res.result === 402) {
            this.$confirm({
              title: res.message,
              okText: "确认",
              cancelText: "取消",
              onOk: () => {
                this.table_submit(true, item);
              },
              onCancel() {},
            });
          }
        });
    },
    changeChannelSmsAccount(val) {
      this.preSubmitAuditTemplateId = val.id;
      this.channelSmsAccountId = val.channelSmsAccountId;
      this.changeChannelSmsAccountShow = true;
    },
    searchSmsAccountList(accessKeyOrName) {
      if (this.searchSmsAccountTimer) {
        clearTimeout(this.searchSmsAccountTimer);
        this.searchSmsAccountTimer = null;
      }
      this.searchSmsAccountTimer = setTimeout(() => {
        this.getSmsAccountList(accessKeyOrName);
      }, 300);
    },
    getSmsAccountList(accessKeyOrName) {
      let data = {
        pageNo: 1,
        pageSize: 1000,
        accessKeyOrName,
      };
      this.$http.get("/sms/platform/list", data).then((res) => {
        if (res.result === 200) {
          this.smsAccountList = res.data.records;
        }
      });
    },
    changeChannelSmsAccountSubmit() {
      this.syncLoading = this.$syncLoading({
        message: "修改中...",
      });
      let data = {
        preSubmitAuditTemplateId: this.preSubmitAuditTemplateId,
        channelSmsAccountId: this.channelSmsAccountId,
      };

      this.$http
        .json_post("/preSubmitAuditTemplate/changeChannelSmsAccount", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.changeChannelSmsAccountShow = false;
            this.syncLoading.close();
            this.inquire(1);
          }
        });
    },
    batchDelete() {
      this.$confirm({
        title: "确认删除吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let arr = [];
          this.selectedRowKeys.forEach((id) => {
            this.tableDataSource.forEach((item) => {
              if (item.id == id) {
                arr.push(item);
              }
            });
          });
          this.$axios
            .all(
              arr.map((item) => {
                let data = {
                  id: item.id,
                };
                return this.$http.json_post(
                  "/preSubmitAuditTemplate/delete",
                  data
                );
              })
            )
            .then((resArr) => {
              this.$message.success("删除成功");
              this.inquire();
            });
        },
        onCancel: () => {},
      });
    },
    resetFailureStatus(val) {
      this.$confirm({
        title: "确认重置吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .json_post("/preSubmitAuditTemplate/resetFailureStatus", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("重置成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
    updateRetryGroup(val) {
      this.preSubmitAuditTemplateId = val.id;
      this.retryGroupId = val.retryGroupId;
      this.getChannelRetryGroup();
      this.updateRetryGroupTitle = "设置自动失败重提分组";
      this.updateRetryGroupShow = true;
    },
    batchUpdateRetryGroup() {
      this.retryGroupId = -1;
      this.getChannelRetryGroup();
      this.updateRetryGroupTitle = "批量设置自动失败重提分组";
      this.updateRetryGroupShow = true;
    },
    updateRetryGroupSubmit() {
      let data = {
        retryGroupId: this.retryGroupId,
      };
      if (this.updateRetryGroupTitle == "批量设置自动失败重提分组") {
        this.$axios
          .all(
            this.selectedRows.map((item) => {
              let params = { ...data, preSubmitAuditTemplateId: item.id };
              return this.$http.json_post(
                "/preSubmitAuditTemplate/updateRetryGroup",
                params
              );
            })
          )
          .then((resArr) => {
            this.$message.success("设置成功");
            this.selectedRows.splice(0);
            this.selectedRowKeys.splice(0);
            this.inquire();
            this.updateRetryGroupShow = false;
          });
      } else if (this.preSubmitAuditTemplateId) {
        data.preSubmitAuditTemplateId = this.preSubmitAuditTemplateId;
        this.$http
          .json_post("/preSubmitAuditTemplate/updateRetryGroup", data)
          .then((res) => {
            if (res.result === 200) {
              this.$message.success("设置成功");
              this.inquire();
              this.updateRetryGroupShow = false;
            }
          });
      }
    },
    getChannelRetryGroup() {
      let data = {
        pageNo: 1,
        pageSize: 5000,
      };
      this.$http
        .get("/channelRetryGroup/channelRetryGroupPage", data)
        .then((res) => {
          if (res.result === 200) {
            this.channelRetryGroupList = res.data.records;
            this.channelRetryGroupList.unshift({
              id: -1,
              name: "无",
              enabled: true,
            });
          }
        });
    },
    getChannelSendReport(val) {
      let data = {
        preSubmitAuditTemplateId: val.id,
      };
      this.syncLoading = this.$syncLoading({
        message: "分析中...",
      });
      this.$http
        .get("/preSubmitAuditTemplate/getChannelSendReport", data)
        .then((res) => {
          if (res.result === 200) {
            let list = res.data;
            let summary = {
              key: list.length + 1,
              channelSmsAccountName: "汇总",
              failureCount: 0,
              successCount: 0,
              totalCount: 0,
              waitCallbackCount: 0,
              waitSubmitCount: 0,
              clickUv: 0,
              successRate: "0%",
              clickUvRate: "0%",
              waitSubmitProportion: "0%",
              successProportion: "0%",
              waitCallbackProportion: "0%",
              failureProportion: "0%",
            };

            list.forEach((item, index) => {
              summary.failureCount += item.failureCount;
              summary.successCount += item.successCount;
              summary.totalCount += item.totalCount;
              summary.waitCallbackCount += item.waitCallbackCount;
              summary.waitSubmitCount += item.waitSubmitCount;
              if (!item.clickUv) {
                item.clickUv = 0;
              }
              summary.clickUv += item.clickUv;

              item.successRate = "0%";
              item.clickUvRate = "0%";
              item.key = index + 1;
              if (
                item.successCount + item.failureCount + item.waitCallbackCount >
                0
              ) {
                item.successRate =
                  (
                    (item.successCount /
                      (item.successCount +
                        item.failureCount +
                        item.waitCallbackCount)) *
                    100
                  ).toFixed(2) + "%";
              }
              if (item.successCount > 0) {
                item.clickUvRate =
                  ((item.clickUv / item.successCount) * 100).toFixed(2) + "%";
              }

              item.waitSubmitProportion = "0%";
              item.successProportion = "0%";
              item.waitCallbackProportion = "0%";
              item.failureProportion = "0%";
              if (item.totalCount > 0) {
                item.waitSubmitProportion =
                  ((item.waitSubmitCount / item.totalCount) * 100).toFixed(2) +
                  "%";
                item.successProportion =
                  ((item.successCount / item.totalCount) * 100).toFixed(2) +
                  "%";
                item.waitCallbackProportion =
                  ((item.waitCallbackCount / item.totalCount) * 100).toFixed(
                    2
                  ) + "%";
                item.failureProportion =
                  ((item.failureCount / item.totalCount) * 100).toFixed(2) +
                  "%";
              }
            });

            if (
              summary.successCount +
                summary.failureCount +
                summary.waitCallbackCount >
              0
            ) {
              summary.successRate =
                (
                  (summary.successCount /
                    (summary.successCount +
                      summary.failureCount +
                      summary.waitCallbackCount)) *
                  100
                ).toFixed(2) + "%";
            }
            if (summary.successCount > 0) {
              summary.clickUvRate =
                ((summary.clickUv / summary.successCount) * 100).toFixed(2) +
                "%";
            }

            if (summary.totalCount > 0) {
              summary.waitSubmitProportion =
                ((summary.waitSubmitCount / summary.totalCount) * 100).toFixed(
                  2
                ) + "%";
              summary.successProportion =
                ((summary.successCount / summary.totalCount) * 100).toFixed(2) +
                "%";
              summary.waitCallbackProportion =
                (
                  (summary.waitCallbackCount / summary.totalCount) *
                  100
                ).toFixed(2) + "%";
              summary.failureProportion =
                ((summary.failureCount / summary.totalCount) * 100).toFixed(2) +
                "%";
            }
            list.push(summary);
            this.syncLoading.close();
            this.channelSendReportTableData = list;
            this.channelSendReportShow = true;
          }
        });
    },
    getChannelRetrySendQueuePage(index, val) {
      if (index) {
        this.channelRetrySendQueuePagePageNo = index;
      }
      if (val) {
        this.preSubmitAuditTemplateId = val.id;
        this.customerCode = "";
        this.channelSmsAccountId = undefined;
        this.originalChannelSmsAccountId = undefined;
      }
      let data = {
        pageNo: this.channelRetrySendQueuePagePageNo,
        pageSize: 10,
        preSubmitAuditTemplateId: this.preSubmitAuditTemplateId,
        customerCode: this.customerCode,
        status: "WAIT_SUBMIT",
        channelSmsAccountId: this.channelSmsAccountId,
        originalChannelSmsAccountId: this.originalChannelSmsAccountId,
      };
      this.loadingChannelRetrySendQueuePageTable = true;
      this.$http
        .get("/channelRetryGroup/channelRetrySendQueuePage", data)
        .then((res) => {
          this.loadingChannelRetrySendQueuePageTable = false;
          if (res.result === 200) {
            this.channelRetrySendQueuePageTableData = res.data.records;
            this.channelRetrySendQueuePagePagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.getChannelRetrySendQueuePage(current),
            };
            this.channelRetrySendQueuePageShow = true;
          }
        })
        .catch(() => {
          this.loadingChannelRetrySendQueuePageTable = false;
        });
    },
    updateAutoResendFailurePolicy(val) {
      this.$confirm({
        title:
          "确认" +
          (val.autoResendFailureDisabled ? "开启" : "关闭") +
          "自动重提吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            preSubmitAuditTemplateId: val.id,
            autoResendFailureDisabled: !val.autoResendFailureDisabled,
          };
          this.$http
            .json_post(
              "/preSubmitAuditTemplate/updateAutoResendFailurePolicy",
              data
            )
            .then((res) => {
              if (res.result === 200) {
                this.$message.success(
                  (val.autoResendFailureDisabled ? "开启" : "关闭") + "成功"
                );
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: val.id,
          };
          this.$http
            .json_post("/preSubmitAuditTemplate/delete", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire();
              }
            });
        },
        onCancel() {},
      });
    },
    // 点击条数
    numClick(val, sendStatus) {
      if (val.name == "汇总") {
        return;
      }

      this.id = val.id;
      this.getPlanIdList();
      this.customerCode = "";
      this.planId = undefined;
      this.originalStatus = val.status;
      this.channelSmsAccountId = undefined;
      this.sendStatus = sendStatus;
      this.submitTime = ["", ""];
      switch (sendStatus) {
        case "WAIT_SUBMIT":
          this.title = "等待提交";
          break;
        case "SUCCESS":
          this.title = "发送成功";
          break;
        case "FAILURE":
          this.title = "发送失败";
          break;
        case "SUBMIT_SUCCESS":
          this.title = "提交成功";
          break;
        default:
          this.title = "总";
          break;
      }
      this.getItemList();
      this.numShow = true;
    },
    getPlanIdList() {
      let data = {
        templateId: this.id,
      };
      this.$http
        .get("/preSubmitAuditTemplate/planIdListByTemplateId", data)
        .then((res) => {
          if (res.result === 200) {
            this.planIdList = res.data;
          }
        });
    },
    getItemList(index) {
      if (index) {
        this.numPage = index;
      }
      let data = {
        pageNo: this.numPage,
        pageSize: 10,
        auditTemplateId: this.id,
        customerCode: this.customerCode,
        planId: this.planId,
        sendStatus: this.sendStatus,
        channelSmsAccountId: this.channelSmsAccountId,
        submitTimeStart: this.submitTime[0],
        submitTimeEnd: this.submitTime[1],
      };
      this.loadingNumTable = true;
      this.$http
        .get("/preSubmitAuditTemplate/itemList", data)
        .then((res) => {
          this.loadingNumTable = false;
          if (res.result === 200) {
            this.numTableData = res.data.records;
            this.numPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              onChange: (current) => this.numChangePage(current),
            };
          }
        })
        .catch(() => {
          this.loadingNumTable = false;
        });
    },
    numChangePage(index) {
      this.getItemList(index);
    },
    numItemResetFailureStatus() {
      this.$confirm({
        title: "确认重置状态为发送失败的吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            id: this.id,
            customerCode: this.customerCode,
            planId: this.planId,
            channelSmsAccountId: this.channelSmsAccountId,
            submitTimeStart: this.submitTime[0],
            submitTimeEnd: this.submitTime[1],
          };
          this.$http
            .json_post("/preSubmitAuditTemplate/resetFailureStatus", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("重置成功");
                this.getItemList(1);
              }
            });
        },
        onCancel() {},
      });
    },
    // 选择发送日期
    handleGmtCreatedStartOpenChange(open) {
      if (!open) {
        this.gmtEndOpen = true;
      }
    },
    handleGmtCreatedEndOpenChange(open) {
      this.gmtEndOpen = open;
    },
    // 启用状态
    switchOnChange(record) {
      let data = {
        id: record.id,
        enabled: !record.enabled,
      };
      this.$http
        .json_post("/preSubmitAuditTemplate/switchEnableStatus", data)
        .then((res) => {
          if (res.result === 200) {
            this.$message.success("修改成功");
            this.inquire();
          }
        });
    },
  },
};
</script>
