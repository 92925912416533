<template>
  <div class="smsStatusInfo">
    <div class="smsStatusInfo-search">
      <div class="smsStatusInfo-search-1">
        客户编号:
        <a-input
          v-model="customerCode"
          placeholder="请输入客户编号"
          allowClear
        />
      </div>
      <div class="smsStatusInfo-search-1">
        表名:
        <a-select
          v-model="tableNum"
          placeholder="请选择表名"
          class="select-tag"
          @change="inquire()"
        >
          <a-select-option
            v-for="i in tableNumList"
            :key="i.value"
            :value="i.value"
            >{{ i.label }}</a-select-option
          >
        </a-select>
      </div>
      <div class="smsStatusInfo-search-1">
        类型:
        <a-select
          v-model="sourceList"
          placeholder="请选择类型"
          class="select-tag"
          mode="multiple"
          @change="inquire()"
          allowClear
        >
          <a-select-option
            v-for="(i, index) in typeList"
            :key="index"
            :value="i.name"
            >{{ i.name }}</a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="smsStatusInfo-search-button">
      <a-button type="primary" @click="inquire(1)">查 询</a-button>
      <a-button type="primary" @click="blackAdd">新 增</a-button>
    </div>
    <!-- 平台列表 -->
    <a-table
      bordered
      :rowClassName="$common.rowClassColor"
      :components="$common.getTitle(signColumns)"
      :columns="signColumns"
      :dataSource="signTableData"
      :pagination="signPagination"
      :loading="loadingTable"
      size="small"
    >
      <span slot="action" slot-scope="text, record" style="width: 100%">
        <a href="javascript:;" @click="deleteClick(record)" style="color: red"
          >删除</a
        >
      </span>
    </a-table>
    <!-- 新增 -->
    <a-modal
      title="新增"
      v-model="blackShow"
      :maskClosable="false"
      class="actionSub-class"
      width="500px"
    >
      <a-form>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="客户编号"
        >
          <a-textarea
            v-model="customerCodeAdd"
            placeholder="请输入客户编号, 多个用逗号分割"
            :rows="4"
          />
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="类型"
        >
          <a-select
            v-model="sourceListAdd"
            placeholder="请选择类型"
            class="select-tag"
            mode="multiple"
          >
            <a-select-option
              v-for="(i, index) in typeList"
              :key="index"
              :value="i.name"
              >{{ i.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 16 }"
          label="备注"
        >
          <a-input v-model="remarkAdd" placeholder="请输入备注" />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <div style="display: flex; justify-content: center">
          <a-button key="back" @click="blackShow = false">取消</a-button>
          <a-button key="submit" type="primary" @click="add_submit"
            >确定</a-button
          >
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "platList",
  data() {
    return {
      platTitle: "",
      signColumns: [
        {
          title: "编号",
          align: "center",
          ellipsis: true,
          dataIndex: "id",
          width: 100,
        },
        {
          title: "客户编号",
          ellipsis: true,
          dataIndex: "customerCode",
          width: 150,
        },
        {
          title: "客户省份",
          ellipsis: true,
          dataIndex: "province",
          width: 100,
        },
        {
          title: "客户城市",
          ellipsis: true,
          dataIndex: "city",
          width: 100,
        },
        {
          title: "运营商",
          ellipsis: true,
          dataIndex: "operator",
          width: 100,
        },
        {
          title: "类型",
          ellipsis: true,
          dataIndex: "source",
          width: 150,
        },
        {
          title: "备注",
          ellipsis: true,
          dataIndex: "remark",
          width: 200,
        },
        {
          title: "创建时间",
          ellipsis: true,
          dataIndex: "gmtCreated",
          width: 170,
        },
        {
          title: "操作",
          key: "action",
          width: 60,
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      signTableData: [],
      signPagination: {},
      loadingTable: false,
      currentPage: 1,
      pageSize: 10,
      customerCode: "",
      tableNum: "0",
      tableNumList: [
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
        {
          label: "6",
          value: "6",
        },
        {
          label: "7",
          value: "7",
        },
        {
          label: "8",
          value: "8",
        },
        {
          label: "9",
          value: "9",
        },
        {
          label: "a",
          value: "a",
        },
        {
          label: "b",
          value: "b",
        },
        {
          label: "c",
          value: "c",
        },
        {
          label: "d",
          value: "d",
        },
        {
          label: "e",
          value: "e",
        },
        {
          label: "f",
          value: "f",
        },
      ],
      blackShow: false,
      customerCodeAdd: "",
      remarkAdd: "",
      typeList: [],
      sourceList: [],
      sourceListAdd: [],
    };
  },
  mounted() {
    this.getTypeList();
    this.inquire(1);
  },
  methods: {
    getTypeList() {
      this.$http.get("/blacklist/typeList").then((res) => {
        this.loadingTable = false;
        if (res.result === 200) {
          this.typeList = res.data;
        }
      });
    },
    // 点击查询
    inquire(index) {
      if (index) {
        this.currentPage = index;
      }
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        customerCode: this.customerCode.trim(),
        tableNum: this.tableNum,
        source: this.sourceList.join(","),
      };
      this.getPlatList(data);
    },
    // 查询平台列表
    getPlatList(data) {
      this.loadingTable = true;
      this.$http
        .get("/blacklist/list", data)
        .then((res) => {
          this.loadingTable = false;
          if (res.result === 200) {
            let list = res.data.records;
            list.forEach((item, index) => {
              item.key = index + 1;
            });
            this.signTableData = list;
            this.signPagination = {
              showQuickJumper: true,
              showTotal: () => `共${res.data.total}条`,
              pageSize: res.data.pageSize,
              current: res.data.pageNo,
              total: res.data.total,
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              onShowSizeChange: (current, pageSize) => {
                this.pageSize = pageSize;
                this.inquire(1);
              },
              onChange: (current) => this.currentPage_batch(current),
            };
          }
        })
        .catch((err) => {
          this.loadingTable = false;
        });
    },
    // 翻页
    currentPage_batch(index) {
      this.inquire(index);
    },
    // 点击删除
    deleteClick(val) {
      this.$confirm({
        title: "确认删除客户编号【" + val.customerCode + "】的黑名单吗",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          let data = {
            customerCode: val.customerCode,
            sourceList: [val.source],
          };
          this.$http
            .json_post("/blacklist/deleteWithSource", data)
            .then((res) => {
              if (res.result === 200) {
                this.$message.success("删除成功");
                this.inquire(1);
              }
            });
        },
        onCancel() {},
      });
    },
    blackAdd() {
      this.customerCodeAdd = "";
      this.remarkAdd = "";
      this.sourceListAdd.splice(0);
      this.sourceListAdd.push("高投诉意向人群(回复)");
      this.blackShow = true;
    },
    add_submit() {
      let data = {
        customerCode: this.customerCodeAdd,
        sources: this.sourceListAdd.join(","),
        remark: this.remarkAdd,
      };
      this.$http.json_post("/blacklist/add", data).then((res) => {
        if (res.result === 200) {
          this.$message.success("新增成功");
          this.blackShow = false;
          this.inquire(1);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.smsStatusInfo {
  .smsStatusInfo-search {
    position: relative;
    margin-bottom: 20px;
    &::after {
      content: "";
      clear: both;
      display: block;
    }
    .smsStatusInfo-search-1 {
      float: left;
      margin-right: 20px;

      .select-tag {
        width: 240px !important;
      }
    }

    input {
      width: 240px;
    }

    span {
      width: 240px;
    }
  }
  .smsStatusInfo-search-button {
    margin-bottom: 20px;
    button {
      margin-right: 20px;
    }
  }
}
</style>
